import Layout from "../components/Layout";
import React, { useEffect } from "react";
import { EventHeaderDiv } from "../styles/event";
import EventDetailHero from "../components/EventDetailHero";
import EventBody from "../components/EventBody";
import EventMap from "../components/EventMap";
import { graphql } from "gatsby";
import {
  getFormatShortDayNoTimeZone,
  getTime,
  formatTime,
  getTwoDifferenceDayFormatNoTimeZone,
  getFormatTwoShortDayNoTimeZone,
} from "./../modules/time";
import { handleLocation } from "../modules/eventLocation";
import ImagesSwiper from "../components/ImagesSwiper";
import AboutParticipant from "../components/AboutParticipant";
import EventListGrid from "../components/EventListGrid";

const EventPage = ({ data, pageContext }) => {
  const {
    id,
    title,
    headerImage,
    richContent,
    eventStartDateTime,
    eventEndDateTime,
    eventShortDescription,
    qafEventLocation,
    showSocialShareButton,
    qafEventType,
    subtitle,
    callToAction,
    eventUserAddToCalendar,
    showEventTitle,
    showSubtitle,
    registerNowLink,
    eventLink,
    qafEventMapTitle,
    qafEventMapAddress,
    qafEventMapLink,
    qafEventMapImage,
    qafEventImageSlider,
    mapLocationText,
  } = data.craftEntryInterface;

  let eventStart = eventStartDateTime ? formatTime(getTime(eventStartDateTime)) : null;
  const eventEnd = eventEndDateTime ? formatTime(getTime(eventEndDateTime)) : null;

  let mainDate =
    new Date(eventStartDateTime)?.getMonth() !== new Date(eventEndDateTime)?.getMonth() || !eventStartDateTime || !eventEndDateTime
      ? getTwoDifferenceDayFormatNoTimeZone(eventStartDateTime, eventEndDateTime)
      : new Date(eventStartDateTime)?.getDate() === new Date(eventEndDateTime)?.getDate()
      ? getFormatShortDayNoTimeZone(eventStartDateTime, true)
      : getFormatTwoShortDayNoTimeZone(eventStartDateTime, eventEndDateTime, true);

  const now = new Date();
  const relatedEventsListParticipant = data?.allEvents?.nodes?.filter((item) => {
    const thisDate = new Date(item.eventEndDateTime || item.eventStartDateTime || null);
    return thisDate >= now;
  });
  const relatedEventsList = data?.allEvents?.nodes?.filter((event) => {
    const thisDate = new Date(event.eventEndDateTime || event.eventStartDateTime || null);
    return thisDate >= now && event?.qafEventType?.some((type) => qafEventType?.map((t) => t?.id)?.includes(type?.id)) && event?.id !== id;
  });

  const communityMembers = qafEventLocation?.length
    ? qafEventLocation.filter((item) => item?.qafParticipantType && item?.qafParticipantType?.length)
    : [];

  useEffect(() => {
    for (let i = 0; i < communityMembers?.length; i++) {
      const relatedE = relatedEventsListParticipant.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter((item) => item?.qafParticipantType && item?.qafParticipantType?.length)
          : [];

        return checkedArray.find((item) => item?.id === communityMembers[i]?.id);
      });
      communityMembers[i].relatedEvents = relatedE;
    }
  }, []);

  return (
    <Layout backgroundColor={"var(--bg-color)"} skipHeader headerBlack={true} title={title}>
      <EventHeaderDiv>
        <EventDetailHero
          titleText={title}
          subTitleText={subtitle}
          eventTypes={qafEventType}
          headerImages={headerImage}
          showTitle={showEventTitle}
          showSubtitle={showSubtitle}
        />
      </EventHeaderDiv>

      <EventBody
        subTitle={eventShortDescription}
        addToCalendar={
          eventUserAddToCalendar && {
            title: title,
            description: eventShortDescription || subtitle || "",
            location: handleLocation(qafEventLocation),
            startTime: eventStartDateTime,
            endTime: eventEndDateTime,
          }
        }
        date={mainDate}
        startTime={eventStart}
        endTime={eventEnd}
        venue={handleLocation(qafEventLocation)}
        mainColumn={richContent}
        leftInfo={true}
        communityInfo={null}
        isSmallTitle={true}
        showSocialShareButton={showSocialShareButton}
      />

      {qafEventImageSlider?.length ? <ImagesSwiper images={qafEventImageSlider} /> : null}

      {communityMembers?.length ? <AboutParticipant participants={communityMembers} /> : null}

      {qafEventMapImage?.length ? (
        <EventMap qafEventMapImage={qafEventMapImage} mapLocationText={mapLocationText} qafEventLocation={qafEventLocation} />
      ) : null}

      {relatedEventsList?.length ? <EventListGrid title={"Related Events"} events={relatedEventsList} itemsPerLine={3} hideArrow /> : null}
    </Layout>
  );
};

export default EventPage;

export const pageQuery = graphql`
  query ($id: String!) {
    eventGlobalData: allCraftSiteQafSettingsGlobalSet {
      nodes {
        venuesLocation
        siteQAFSettingAddress
        siteQAFSettingMapButtonLink
      }
    }
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_qafEvents_qafEvent_Entry {
        id
        title
        qafEventType {
          ... on Craft_qafEventType_default_Entry {
            id
            title
          }
        }
        eventStartDateTime
        eventEndDateTime
        eventUserAddToCalendar
        showEventTitle
        showSubtitle
        registerNowLink
        mapLocationText
        headerImage: qafEventHeaderImage {
          ... on Craft_eventsAssets_Asset {
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440, quality: 100, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        qafEventImageSlider {
          ... on Craft_eventsAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, height: 365) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        qafEventMapImage {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1240, maxHeight: 500, quality: 100, fit: COVER) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1240, maxHeight: 500, quality: 100, fit: COVER) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        eventLink
        qafEventLocation {
          slug
          title
          ... on Craft_participants_default_Entry {
            participantLocation {
              title
            }
            communityMemberOpeningHours
            communityMemberSlug
            communityMemberWebsite
            communityMemberEmailAddress
            communityMemberDescription
            communityMemberContactDetails {
              col1
              col2
            }
            qafParticipantType {
              ... on Craft_qafParticipantType_default_Entry {
                id
                title
              }
            }
            headerImage: qafParticipantHeaderImage {
              ... on Craft_communityAssets_Asset {
                localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 295, quality: 100, fit: COVER) {
                      src
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 295, quality: 100, fit: COVER) {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on Craft_maplocation_default_Entry {
            locationId
            locationName
            title
            allowClick
          }
        }
        showSocialShareButton
        subtitle
        eventShortDescription
        callToAction
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, quality: 100, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
    allEvents: allCraftQafEventsQafEventEntry {
      nodes {
        id
        title
        isStaging
        eventEndDateTime
        eventStartDateTime
        eventShortDescription
        qafEventLocation {
          title
          ... on Craft_participants_default_Entry {
            participantLocation {
              title
            }
            communityMemberOpeningHours
            communityMemberSlug
            communityMemberWebsite
            communityMemberEmailAddress
            communityMemberDescription
            communityMemberContactDetails {
              col1
              col2
            }
            qafParticipantType {
              ... on Craft_qafParticipantType_default_Entry {
                id
                title
              }
            }
          }
          ... on Craft_maplocation_default_Entry {
            locationId
            locationName
            title
            allowClick
          }
        }
        slug
        qafEventType {
          ... on Craft_qafEventType_default_Entry {
            id
            title
          }
        }
        headerImage: qafEventHeaderImage {
          ... on Craft_eventsAssets_Asset {
            cardImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 295, quality: 100, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
