import styled from "styled-components";

export const EventHeaderDiv = styled.div`
  --headerHeight: 110px;
  padding-top: var(--headerHeight);
`;

export const RelatedEventDiv = styled.div`
  /* width: var(--width);
  max-width: var(--width); */
  padding: 50px var(--outerMargin);
  margin: 0 auto;
  border: 10px solid
    ${(props) => {
      if (props.backgroundColorDiv === 1) {
        return "var(--red)";
      } else if (props.backgroundColorDiv === 3) {
        return "var(--yellow2)";
      } else if (props.backgroundColorDiv === 2) {
        return "var(--purple2)";
      } else {
        return "var(--primary)";
      }
    }};
  border-top: none;
  border: ${(props) => !props.useBorder && "none"};
  & > p {
    color: var(--white);
    margin: 0 0 20px 0;
    font-size: 32px;
    font-weight: 400;
    & > span {
      margin-right: 25px;
    }
  }
  @media (max-width: 768px) {
    --outerMargin: 20px;
    & p {
      font-size: 24px;
    }
    & svg {
      width: 20px;
      height: 10px;
    }
  }
  @media (max-width: 576px) {
    border: none;
  }
`;
