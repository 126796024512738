import React, { Fragment } from "react";
import { H3, H3Small, H3SmallStrong, H4Big } from "./../Typography";
import { EventMapDiv } from "./elements";
import Img from "gatsby-image";
import { navigate } from "gatsby";

const EventMap = ({ qafEventMapImage, mapLocationText, qafEventLocation }) => {
  const locationName = qafEventLocation
    ?.filter((location) => !!location?.locationId)
    ?.map((location) => location?.title)
    ?.join(", ");

  return (
    <Fragment>
      <EventMapDiv backgroundColor={"var(--primary)"}>
        <div className={qafEventMapImage?.length ? null : "noImage"}>
          {locationName ? <H3SmallStrong>{locationName}</H3SmallStrong> : null}

          {mapLocationText ? <H4Big>{mapLocationText}</H4Big> : null}

          {qafEventMapImage?.length ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                maxHeight: "500px",
                cursor: "var(--cursor-hover) 0 0, pointer",
              }}
              onClick={() => navigate("/map")}
            >
              <Img
                style={{
                  maxHeight: "500px",
                }}
                fluid={qafEventMapImage[0]?.localFile?.childImageSharp?.fluid}
                alt="Event Map"
              />
            </div>
          ) : null}
        </div>
      </EventMapDiv>
    </Fragment>
  );
};

export default EventMap;
