import React, { Fragment } from "react";
import { H5SmallMedium, H2BigStrong, H2Big } from "./../Typography";
import { EventTextDetailDiv, EventTypeWrapper, FluidImageDiv } from "./elements";
import Img from "gatsby-image";
import { AnimationOnScroll } from "react-animation-on-scroll";

const EventDetailHero = ({ titleText, subTitleText, eventTypes, headerImages, showTitle, showSubtitle }) => {
  return (
    <Fragment>
      <AnimationOnScroll
        animatePreScroll={true}
        animateIn="animate__zoomInDown"
        // animateOut="animate__zoomOutUp"
        animateOnce
        duration={1.75}
        offset={50}
      >
        <EventTextDetailDiv>
          <div>
            {showTitle ? <H2BigStrong>{titleText}</H2BigStrong> : null}
            {showSubtitle ? <H2Big>{subTitleText}</H2Big> : null}
          </div>
          <EventTypeWrapper>
            {eventTypes?.map((eventType, eventTypeIndex) => {
              return (
                <div key={eventTypeIndex}>
                  <H5SmallMedium>{eventType?.title}</H5SmallMedium>
                  {eventTypeIndex === eventTypes?.length - 1 ? null : <span className="separate"></span>}
                </div>
              );
            })}
          </EventTypeWrapper>
        </EventTextDetailDiv>
      </AnimationOnScroll>
      {headerImages && headerImages.length && (
        <FluidImageDiv>
          <Img fixed={headerImages[0]?.wide?.childImageSharp?.fixed} />
        </FluidImageDiv>
      )}
    </Fragment>
  );
};

export default EventDetailHero;
