import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 100px 50px 100px;
  color: var(--text-color);
  > h4 {
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    padding: 30px 20px 36px 20px;
  }
  > div + div {
    margin-top: 50px;
  }
`;
