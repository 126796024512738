import React from "react";
import { Container } from "./elements";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper";

const ImagesSwiper = ({ images }) => {
  return (
    <Container>
      <Swiper
        className="mySwiper"
        direction="horizontal"
        modules={[Autoplay, Mousewheel]}
        autoplay={{
          enabled: true,
          delay: 2000,
          // pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        speed={1500}
        loop={images?.length >= 3}
        slidesPerView={"auto"}
      >
        {images?.map((item) => (
          <SwiperSlide key={item.id}>
            <img src={item?.localFile?.childImageSharp?.fixed?.src} alt={`Quoz arts fest event images slider`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default ImagesSwiper;
