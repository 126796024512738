import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 50px 0 50px 100px;
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);

  > div {
    flex: 1;
  }

  .swiper {
    width: 100%;
    height: 365px;
  }

  .swiper-slide {
    cursor: var(--cursor-hover) 0 0, pointer !important;
    width: fit-content;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-right: 20px;
  }

  .swiper-slide img {
    object-fit: cover !important;
    height: 365px;
    width: auto;
    max-width: 100%;
    @media (max-width: 1000px) {
      height: 300px;
    }
  }

  @media (max-width: 1000px) {
    padding: 36px 20px;
    margin: 0;
    .swiper {
      min-width: unset;
      width: 100%;
      height: 300px;
    }
  }
`;
