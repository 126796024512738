import React, { useEffect, useState } from "react";
import { Container, InfoBlock } from "./elements";
import Img from "gatsby-image";
import { H3, H3Small, H3SmallStrong, H5, H5SmallStrong } from "../Typography";
import MiscSocialSmallParticipant from "../icons/MiscSocialSmallParticipant";
import useWindowSize from "../../hooks/useWindowSize";

const ParticipantInfoItem = ({ participant }) => {
  const [textEllipsis, setTextEllipsis] = useState(null);
  const windowSize = useWindowSize();

  const socialMethods = ["linkedin", "twitter", "facebook", "instagram"];

  const participantLinks = {
    linkedin: participant?.communityMemberContactDetails?.find((item) => item?.col1?.toLowerCase() === "linkedin")?.col2,
    twitter: participant?.communityMemberContactDetails?.find((item) => item?.col1?.toLowerCase() === "twitter")?.col2,
    facebook: participant?.communityMemberContactDetails?.find((item) => item?.col1?.toLowerCase() === "facebook")?.col2,
    instagram: participant?.communityMemberContactDetails?.find((item) => item?.col1?.toLowerCase() === "instagram")?.col2,
    website: participant?.communityMemberWebsite,
  };

  let showSocialLink = false;
  for (const [key, value] of Object.entries(participantLinks)) {
    if (value) {
      showSocialLink = true;
      break;
    }
  }
  const participantLinksOther = participant?.communityMemberContactDetails?.filter((item) => !socialMethods.includes(item?.col1?.toLowerCase()) && !!item?.col2);

  useEffect(() => {
    const parent = document.querySelector(`#${participant?.slug}`);
    const child = Array.from(document.querySelectorAll(`#${participant?.slug} p`));
    child.forEach((p) => {
      p.classList.remove("text-ellipsis");
    });
    const childOverflow = child?.find((p) => {
      return parent?.offsetTop + parent?.offsetHeight < p?.offsetTop + p?.offsetHeight;
    });
    const rowOverflow = Math.floor((parent?.offsetTop + parent?.offsetHeight - childOverflow?.offsetTop) / 31.2);
    childOverflow?.classList?.add("text-ellipsis");
    if (rowOverflow) {
      setTextEllipsis(rowOverflow);
    }
  }, [windowSize]);

  return (
    <Container textEllipsis={textEllipsis}>
      <Img fixed={participant?.headerImage?.[0]?.localFile?.childImageSharp?.fixed} alt={`Quoz arts fest participant - ${participant?.title}`} />
      <div className="info">
        <div>
          <a href={`/participant/${participant?.slug}`}>
            <H3SmallStrong style={{ color: "var(--primary)", marginBottom: "10px" }}>{participant?.title}</H3SmallStrong>
          </a>
          <H3Small
            id={participant?.slug}
            dangerouslySetInnerHTML={{
              __html: participant?.communityMemberDescription,
            }}
          ></H3Small>
        </div>
        {participantLinksOther?.length || participant?.communityMemberEmailAddress || participantLinks ? (
          <div>
            {participantLinksOther?.length || participant?.communityMemberEmailAddress ? (
              <InfoBlock>
                <H5SmallStrong>CONTACT DETAILS</H5SmallStrong>
                {participantLinksOther?.length ? (
                  <>
                    {participantLinksOther?.map((link, linkIndex) => {
                      return link?.col1 === "Phone" || link?.col1 === "Whatsapp" ? (
                        <a href={`tel:${link?.col2}`} key={linkIndex}>
                          <H5>
                            {link?.col1} {link?.col2}
                          </H5>
                        </a>
                      ) : (
                        <H5 key={linkIndex}>
                          {link?.col1} {link?.col2}
                        </H5>
                      );
                    })}
                  </>
                ) : null}
                {participant?.communityMemberEmailAddress ? (
                  <a href={`mailto:${participant?.communityMemberEmailAddress}`}>
                    <H5>{participant?.communityMemberEmailAddress}</H5>
                  </a>
                ) : null}
              </InfoBlock>
            ) : null}

            {showSocialLink ? (
              <InfoBlock style={{ marginTop: "20px" }}>
                <H5SmallStrong style={{ marginBottom: "10px" }}>SOCIAL LINKS</H5SmallStrong>
                <MiscSocialSmallParticipant color={"var(--text-color)"} participantLinks={participantLinks} />
              </InfoBlock>
            ) : null}
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default ParticipantInfoItem;
