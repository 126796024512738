import React from "react";
import { Container } from "./elements";
import { H4Strong } from "../Typography";
import ParticipantInfoItem from "../ParticipantInfoItem";

const AboutParticipant = ({ participants }) => {
  return (
    <Container>
      <H4Strong>About the participant</H4Strong>
      {participants?.length ? participants?.map((participant) => <ParticipantInfoItem key={participant?.slug} participant={participant} />) : null}
    </Container>
  );
};

export default AboutParticipant;
