import React from "react";
import AddToCalendar from "react-add-to-calendar";
import RichContent from "./../RichContent";
import { H2SmallStrong, H5Small, H5SmallStrong, H5Strong } from "./../Typography";
import { EventBodyDiv } from "./elements";
import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { SmallButton } from "./../Buttons";
import { AnimatedButton } from "../Buttons/elements";

const EventBody = ({
  date,
  startTime,
  endTime,
  venue,
  leftInfo,
  communityInfo,
  mainColumn,
  subTitle,
  addToCalendar,
  registerNow,
  isSmallTitle,
  showSocialShareButton
}) => {
  const color = "var(--primary)";

  return (
    <EventBodyDiv color={color} isSmallTitle={isSmallTitle}>
      <H2SmallStrong>{subTitle}</H2SmallStrong>
      <div>
        <div>
          {leftInfo && (
            <div style={{ display: "flex", marginBottom: "25px" }}>
              <div>
                {date ? (
                  <div style={{ display: "flex" }}>
                    <p>
                      <strong>Date</strong>
                    </p>
                    <p>{date}</p>
                  </div>
                ) : null}
                {startTime ? (
                  <div style={{ display: "flex" }}>
                    <p>
                      <strong>Starts</strong>
                    </p>
                    <p>{startTime}</p>
                  </div>
                ) : null}
                {endTime ? (
                  <div style={{ display: "flex" }}>
                    <p>
                      <strong>Ends</strong>{" "}
                    </p>
                    <p>{endTime}</p>
                  </div>
                ) : null}
                {venue ? (
                  <div style={{ display: "flex" }}>
                    <p>
                      <strong>Venue</strong>{" "}
                    </p>
                    <p>{venue}</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {addToCalendar ? (
            <AnimatedButton style={{ marginBottom: "20px" }}>
              <SmallButton backgroundColor={color} color={color === "var(--blue)" ? "var(--white)" : "var(--black)"}>
                <AddToCalendar event={addToCalendar} />
              </SmallButton>
            </AnimatedButton>
          ) : null}
          <p>
            {" "}
            {registerNow ? (
              <a class="btn_a" href={registerNow} target="_blank">
                Register Now
              </a>
            ) : null}{" "}
          </p>
          <H5SmallStrong style={{ marginTop: 0 }}>Share</H5SmallStrong>
          <MiscSocialSmall color={color || "var(--red)"} customOnOff={true} listSocialShow={showSocialShareButton} />
        </div>
        <div>
          <RichContent content={mainColumn} accentColor={color} />
        </div>
        {communityInfo && (
          <ul>
            <li>
              <H5SmallStrong>CONTACT DETAILS</H5SmallStrong>
              {communityInfo.communityMemberContactDetails?.map((item, index) => (
                <p key={`contact${index}`}>
                  {item.col1}
                  {item?.col2 && item.col2.match(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/) ? (
                    <a href={item.col2} target="_blank" style={{ color: "var(--white)" }}>
                      {" "}
                      {item.col2}
                    </a>
                  ) : (
                    ` ${item?.col2}`
                  )}
                </p>
              ))}
              <p>{communityInfo.communityMemberEmailAddress}</p>
              <p>
                <a href={communityInfo.communityMemberWebsite} target="_blank" style={{ color: "var(--white)" }}>
                  {" "}
                  {communityInfo.communityMemberWebsite}
                </a>
              </p>
            </li>
            <li>
              {communityInfo?.communityMemberOpeningHours ? (
                <>
                  <H5SmallStrong>OPENING HOURS</H5SmallStrong>
                  <p>{communityInfo.communityMemberOpeningHours}</p>
                </>
              ) : null}
            </li>
            <li>
              {communityInfo?.participantLocation && communityInfo?.participantLocation?.length ? (
                <>
                  <H5SmallStrong>LOCATION</H5SmallStrong>
                  <p>{communityInfo?.participantLocation[0]?.title}</p>
                </>
              ) : (
                ""
              )}
            </li>
          </ul>
        )}
      </div>
    </EventBodyDiv>
  );
};

export default EventBody;
