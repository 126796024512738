import styled from "styled-components";

export const EventMapDiv = styled.div`
  width: 100%;
  background-color: var(--primary);
  color: var(--text-color);
  & > div {
    padding: 0 var(--outerMargin);
    margin: 50px auto;
    & h3 {
      @media (max-width: 767px) {
        font-size: 21px;
      }
    }
    & h4 {
      margin-bottom: 25px;
      p @media (max-width: 767px) {
        font-size: 21px;
      }
    }

    @media (max-width: 767px) {
      --outerMargin: 20px;
    }
  }
  > .noImage {
    padding: 0;
    margin: 0;
    height: 12px;
  }
`;
