import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 35px;
  width: 100%;
  color: var(--text-color);
  .gatsby-image-wrapper {
    width: 295px;
    height: 295px;
  }
  h3 {
    max-width: 580px;
    max-height: 252px;
    overflow: hidden;
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .text-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${(prop) => prop?.textEllipsis};
    margin-bottom: 0;
    ~ p {
      display: none;
    }
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    min-height: 0;
  }

  @media (max-width: 990px) {
    .gatsby-image-wrapper {
      width: 100%;
    }
    .info {
     width: 100%;
    }
  }
`;

export const InfoBlock = styled.div`
  color: var(--primary);
  margin-top: 12px;
  a {
    color: var(--text-color);
    &:hover {
      color: var(--primary);
    }
  }
`;
